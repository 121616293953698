import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const standardSubscription = createAsyncThunk(
  "standardSubscription",
  async (data) => {
    try {
      const response = await AxiosInstance.post(`/subscription/standard`, data);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const standardSubscriptionSlice = createSlice({
  name: "standardSubscription",
  initialState: {
    isLoading: false,
    standardSubscriptionData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [standardSubscription.pending]: (state) => {
      state.isLoading = true;
    },
    [standardSubscription.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.standardSubscriptionData = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [standardSubscription.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default standardSubscriptionSlice.reducer;
