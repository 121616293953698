import { Modal } from "antd";
import { AiOutlineExclamation } from "react-icons/ai";
import { Cookies } from "react-cookie";
import { API_KEY } from "../environment";
import axios from "axios";

const AxiosInstance = axios.create();

let merchantNotExist = false;
let isModalOpen = false;
const cookies = new Cookies();

const handleCancel = () => {
  cookies.remove("token", { path: "/" });
  cookies.remove("userEmail", { path: "/" });
  cookies.remove("userName", { path: "/" });
  cookies.remove("role", { path: "/" });
  window.location.reload();
  Modal.destroyAll();
  window.location.href = "/";
};
AxiosInstance.interceptors.request.use(
  (config) => {
    const token = cookies.get("token");
    config.baseURL = API_KEY;
    if (process.env.REACT_APP_ENVIRONMENT === "local") {
      config.headers["x-site"] = "https://location-tool.com";
    }
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["x-language"] = cookies.get("lang") || "en";
    return config;
  },
  (error) => Promise.reject(error)
);

AxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401 && !isModalOpen) {
      isModalOpen = true; // Set isModalOpen to true to prevent multiple modals
      Modal.error({
        content: (
          <div className="session-Model">
            <div className="session-modal-logo">
              <div className="bd-logo">
                <AiOutlineExclamation />
              </div>
            </div>
            <div className="session-modal-text">
              {merchantNotExist ? (
                <h3>User does not exist.</h3>
              ) : (
                <>
                  <h3>Session expired</h3>
                  <p>
                    Your current session has expired, and you have been logged
                    out. Please log in again to continue.
                  </p>
                </>
              )}
            </div>
            <div className="session-modal-btn">
              <button
                className="primary_btn btn hl_cta_wrap"
                onClick={handleCancel}
              >
                Go Back to Login
              </button>
            </div>
          </div>
        ),
        centered: true,
        cancelButtonProps: { style: { display: "none" } },
        okButtonProps: { style: { display: "none" } },
        closable: false,
        className: "session_expired_modal",
        onOk: () => {
          isModalOpen = false;
        },
        onCancel: () => {
          isModalOpen = false;
        },
      });
    }
    return Promise.reject(error);
  }
);

export default AxiosInstance;
