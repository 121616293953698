import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
import { logAPI } from "../../utils/commonUtils";
export const subscriptionSpreedlyCreate = createAsyncThunk(
  "subscriptionCreate",
  async (data) => {
    try {
      await logAPI(
        "FE/subscription/v4/create",
        "calling subscription v4 create api from FE",
        data,
        200
      );

      const response = await AxiosInstance.post(
        `/subscription/v4/create`,
        data
      );
      await logAPI(
        "FE/subscription/v4/create",
        "subscription v4 create api success from FE",
        response.data,
        200
      );
      return response.data;
    } catch (error) {
      await logAPI(
        "FE/subscription/v4/create",
        "subscription v4 create api fail from FE",
        error,
        400
      );
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const subscriptionSpreedlyCreateSlice = createSlice({
  name: "subscriptionSpreedlyCreate",
  initialState: {
    isLoading: false,
    subscriptionCreateData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [subscriptionSpreedlyCreate.pending]: (state) => {
      state.isLoading = true;
    },
    [subscriptionSpreedlyCreate.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.subscriptionCreateData = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [subscriptionSpreedlyCreate.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default subscriptionSpreedlyCreateSlice.reducer;
