import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
import { logAPI } from "../../utils/commonUtils";
export const paymentMethod = createAsyncThunk(
  "payment-method-create",
  async (data) => {
    try {
      const response = await AxiosInstance.post(
        `/subscription/payment-method-create`,
        data
      );
      logAPI(
        "FE/subscription/payment-method-create",
        "payment intent api success from FE",
        response.data,
        200
      );
      return response.data;
    } catch (error) {
      logAPI(
        "FE/subscription/payment-method-create",
        "payment intent api success from FE",
        error,
        400
      );
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const paymentMethodSlice = createSlice({
  name: "payment-method-create",
  initialState: {
    isLoading: false,
    paymentMethodData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [paymentMethod.pending]: (state) => {
      state.isLoading = true;
    },
    [paymentMethod.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.paymentMethodData = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [paymentMethod.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default paymentMethodSlice.reducer;
