import {
  Suspense,
  createContext,
  lazy,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import "./scss/style.scss";
import "react-phone-input-2/lib/style.css";
import "./language";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-loading-skeleton/dist/skeleton.css";
import { google_client_id } from "./environment";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Cookies, useCookies } from "react-cookie";

const AppLayout = lazy(() => import("./layouts"));
const AppProvider = lazy(() => import("./provider"));
const AppRoutes = lazy(() => import("./routes"));
const AppContextInput = createContext();
export const useAppContextInput = () => useContext(AppContextInput);

function App() {
  const inputRef = useRef(null);
  const mobileInputRef = useRef(null);
  const [plan, setPlan] = useState("trial");
  const handleInputFocus = (props) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setPlan(props);
    if (window.screen.width > 767) {
      inputRef.current.focus();
    } else {
      mobileInputRef.current.focus();
    }
  };

  return (
    <Suspense>
      {/* Wrap each lazy-loaded component with Suspense */}
      <GoogleOAuthProvider clientId={google_client_id}>
        <AppProvider>
          <AppContextInput.Provider
            value={{
              inputRef,
              mobileInputRef,
              handleInputFocus,
              plan,
            }}
          >
            <Suspense>
              <AppLayout>
                {/* Wrap nested lazy-loaded components with Suspense */}
                <Suspense>
                  <AppRoutes />
                </Suspense>
              </AppLayout>
            </Suspense>
          </AppContextInput.Provider>
        </AppProvider>
      </GoogleOAuthProvider>
    </Suspense>
  );
}

export default App;
