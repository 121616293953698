import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const savegclid = createAsyncThunk("savegclid", async (data) => {
  try {
    const response = await AxiosInstance.post(`/setting/save`, data);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const savegclidSlice = createSlice({
  name: "savegclid",
  initialState: {
    isLoading: false,
    savegclidData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [savegclid.pending]: (state) => {
      state.isLoading = true;
    },
    [savegclid.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.savegclidData = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [savegclid.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default savegclidSlice.reducer;
