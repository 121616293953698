import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
import { Cookies } from "react-cookie";
export const getGclidDetails = createAsyncThunk(
  "getGclidDetails",
  async (ip) => {
    try {
      const cookies = new Cookies();
      const token = cookies.get("token");
      const response = await AxiosInstance.get(`/setting/list/${ip}`);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const getGclidDetailsSlice = createSlice({
  name: "getGclidDetails",
  initialState: {
    isLoading: false,
    getGclidDetailsData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [getGclidDetails.pending]: (state) => {
      state.isLoading = true;
    },
    [getGclidDetails.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.getGclidDetailsData = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [getGclidDetails.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default getGclidDetailsSlice.reducer;
